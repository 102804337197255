import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import '../styles/ContactForm.css'; 

function ContactForm() {
  const [state, handleSubmit] = useForm("movaonjk");

  if (state.succeeded) {
    return <p className="success-message">Merci pour votre message ! Nous vous contacterons bientôt.</p>;
  }

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <h2>Contactez-nous pour devenir sponsor</h2>
      
      <div className="form-group">
        <label htmlFor="name">Nom</label>
        <input id="name" type="text" name="name" required />
      </div>
      
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      
      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" required />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>
      
      <button type="submit" disabled={state.submitting}>
        {state.submitting ? 'Envoi en cours...' : 'Envoyer'}
      </button>
    </form>
  );
}

export default ContactForm;