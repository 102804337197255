import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Header.css';

function Header() {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error("Autoplay was prevented:", error);
        });
      }
    };

    // Essayez de lire la vidéo immédiatement
    playVideo();

    // Ajoutez un gestionnaire d'événements pour la lecture au premier clic/touche
    const handleInteraction = () => {
      playVideo();
      document.removeEventListener('touchstart', handleInteraction);
      document.removeEventListener('click', handleInteraction);
    };

    document.addEventListener('touchstart', handleInteraction);
    document.addEventListener('click', handleInteraction);

    return () => {
      document.removeEventListener('touchstart', handleInteraction);
      document.removeEventListener('click', handleInteraction);
    };
  }, []);

  return (
    <header className="hero">
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        preload="auto"
        poster="chemin/vers/votre/image-poster.jpg"
      >
        <source src="https://s3.eu-west-3.amazonaws.com/majestic.phoenix.cheer/Riberths_show2024-2.mov" type="video/mp4" />
        Votre navigateur ne supporte pas la lecture de vidéos HTML5.
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>{t('header.title')}</h1>
        <p className="hero-description">{t('header.description')}</p>
        {/* <a href="#" className="btn-cta">{t('header.cta')}</a> */}
      </div>
    </header>
  );
}

export default Header;