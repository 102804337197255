import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Sponsors.css';
import ContactForm from './ContactForm';
import SolidarityShop from './SolidarityShop';
import logoNoisyLeGrand from '../assets/images/logo-Noisy-le-Grand.jpg';
import logoIntersport from '../assets/images/sponsor-body-and-soul-studio.jpg';
import logoCreditMutuel from '../assets/images/sponsor-credit-mutuel.jpg';
import logoHelloAsso from '../assets/images/hello-asso-logo.png';

function Sponsors() {
  const { t } = useTranslation();
  const [showContactForm, setShowContactForm] = useState(false);

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };

  return (
    <section className="sponsors">
      <div className="sponsors-content">
        <h2>{t('sponsors.title')}</h2>

        <div className="sponsors-benefits">
          <h3>{t('sponsors.whySponsor')}</h3>
          <ul>
            {t('sponsors.benefits', { returnObjects: true }).map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </div>
        
        <div className="sponsor-actions">
          <button className="btn-cta" onClick={toggleContactForm}>
            {t('sponsors.becomeSponsor')}
          </button>
          <div className="or-divider">{t('sponsors.or')}</div>
          <a 
            href="https://www.helloasso.com/associations/majestic-phoenix-cheer/formulaires/2" 
            className="btn-cta btn-donate" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {t('sponsors.donate')}
            <img src={logoHelloAsso} alt="Hello Asso" />
          </a>
        </div>

        <SolidarityShop />

        {showContactForm && (
          <div className="contact-form-overlay">
            <div className="contact-form-container">
              <button className="close-form" onClick={toggleContactForm}>×</button>
              <ContactForm />
            </div>
          </div>
        )}

        <div className="current-sponsors">
          <h3>{t('sponsors.trustedBy')}</h3>
          <div className="sponsor-logos">
            <img src={logoNoisyLeGrand} alt="Noisy-le-Grand" className="sponsor-logo" />
            <img src={logoIntersport} alt="Body and Soul Studio" className="sponsor-logo" />
            <img src={logoCreditMutuel} alt="Credit Mutuel" className="sponsor-logo" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sponsors;