import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Footer.css';
import iconInstagram from '../assets/images/icon-instagram.svg';
import iconFacebook from '../assets/images/icon-facebook.svg';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>{t('footer.contact')}</h3>
          <p>{t('footer.address')}</p>
          <p>{t('footer.phone')}: +33 7 82 65 56 37</p>
        </div>
        <div className="footer-section">
          <h3>{t('footer.followUs')}</h3>
          <div className="social-links">
            <a href="https://www.instagram.com/majesticphoenixcheer" target="_blank" rel="noopener noreferrer">
              <img src={iconInstagram} alt="Instagram" />
              Instagram
            </a>
            <a href="https://www.facebook.com/p/Majestic-Phoenix-CHEER-100063528522597" target="_blank" rel="noopener noreferrer">
              <img src={iconFacebook} alt="Facebook" />
              Facebook
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>{t('footer.quickLinks')}</h3>
          <ul>
            <li><a href="https://www.majesticphoenixcheer.net/" target="_blank" >{t('footer.oldWebsite')}</a></li>
            {/* Add more links as needed */}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Majestic Phoenix Cheer. {t('footer.allRightsReserved')}</p>
      </div>
    </footer>
  );
}

export default Footer;