import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/SolidarityShop.css';
import chocolatBackground from '../assets/images/chocolat-vente-solidaire.jpg';

const SolidarityShop = () => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const code = 'WBNZFW';

  const handleCopyCode = async (e) => {
    e.preventDefault(); // Empêcher la sélection par défaut
    e.stopPropagation(); // Empêcher la propagation de l'événement
    
    try {
      if (navigator.share && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        await navigator.share({
          title: t('sponsors.solidarityShop.title'),
          text: code
        });
        setIsCopied(true);
      } else {
        await navigator.clipboard.writeText(code);
        setIsCopied(true);
      }
    } catch (err) {
      // En cas d'erreur, sélectionner uniquement le code
      const codeElement = document.querySelector('.code');
      const range = document.createRange();
      range.selectNodeContents(codeElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }

    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="solidarity-shop" style={{ backgroundImage: `url(${chocolatBackground})` }}>
      <div className="solidarity-shop-content">
        <h3>{t('sponsors.solidarityShop.title')}</h3>
        <p className="shop-description">{t('sponsors.solidarityShop.description')}</p>
        
        <div className="shop-code-container">
          <p className="code-instruction">{t('sponsors.solidarityShop.instruction')}</p>
          <div className="code-wrapper">
            <div className="code-display" onClick={handleCopyCode}>
              <span className="code">{code}</span>
              <button 
                className="copy-button" 
                aria-label="Copier le code"
                onClick={handleCopyCode}
              >
                {isCopied ? (
                  <span className="copied-text">✓</span>
                ) : (
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M16 1H4C3 1 2 2 2 3v14h2V3h12V1zm3 4H8C7 5 6 6 6 7v14c0 1 1 2 2 2h11c1 0 2-1 2-2V7c0-1-1-2-2-2zm0 16H8V7h11v14z"/>
                  </svg>
                )}
              </button>
            </div>
            <div className={`copied-feedback ${isCopied ? 'show' : ''}`}>
              {t('sponsors.copied', 'Code copié !')}
            </div>
          </div>
        </div>

        <div className="shop-button-wrapper">
          <a 
            href="https://asso.initiatives.fr" 
            className="btn-cta btn-shop" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {t('sponsors.solidarityShop.cta')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SolidarityShop;