import React, { useState, useEffect, useRef } from 'react';
import '../styles/YouthSummit.css';
import { useTranslation, Trans } from 'react-i18next';

function YouthSummit() {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    const countDownDate = new Date("Apr 24, 2025 00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    };

    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
    };
  }, []);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch(e => {
        console.error("Erreur de lecture vidéo:", e);
      });
    } else {
      videoRef.current.pause();
    }
  };

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(!videoRef.current.muted);
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) { // iOS Safari
        videoRef.current.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // iOS Safari
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <section className="youth-summit">
      <div className="youth-summit-promo">
        <h2>{t('youthSummit.title')}</h2>
        <p>
          <Trans i18nKey="youthSummit.description">
            Notre team <span className="highlight-team">MAGISTRAL</span> va représenter la France 🇫🇷 aux championnats du monde.
          </Trans>
        </p>
        <p>{t('youthSummit.date')}</p>
        <div className="countdown">
          {Object.entries(countdown).map(([unit, value]) => (
            <div key={unit} className="countdown-item">
              <span>{value}</span>
              <span>{t(`youthSummit.countdown.${unit}`)}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="youth-summit-video">
        <div className="video-title">{t('youthSummit.videoTitle')}</div>
        <div className="video-container" ref={videoContainerRef}>
          <video
            ref={videoRef}
            poster="https://s3.eu-west-3.amazonaws.com/majestic.phoenix.cheer/video-poster.jpg"
            preload="metadata"
            playsInline
            muted
            onClick={togglePlay}
          >
            <source src="https://s3.eu-west-3.amazonaws.com/majestic.phoenix.cheer/Promo-Youth-Summit.MP4" type="video/mp4" />
            {t('youthSummit.videoUnsupported')}
          </video>
          {!isPlaying && (
            <div className="play-button" onClick={togglePlay}>
              <img 
                src="https://s3.eu-west-3.amazonaws.com/majestic.phoenix.cheer/animated-play-button.gif"
                alt={t('youthSummit.playButtonAlt')}
              />
            </div>
          )}
          <div className="video-controls">
            <button onClick={toggleMute}>
              {isMuted ? (
                <svg viewBox="0 0 24 24" className="sound-off">
                  <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>
                </svg>
              ) : (
                <svg viewBox="0 0 24 24" className="sound-on">
                  <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
                </svg>
              )}
            </button>
            <button onClick={toggleFullscreen}>
              <svg viewBox="0 0 24 24">
                <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default YouthSummit;