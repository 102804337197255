import React from 'react';
import Header from './components/Header';
import YouthSummit from './components/YouthSummit';
import Sponsors from './components/Sponsors';
import Teams from './components/Teams';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import './styles/index.css';
import './i18n';

function App() {
  return (
    <div className="App">
      <Header />
      <YouthSummit />
      <Sponsors />
      <Teams />
      {/* <Gallery /> */}
      <Footer />
    </div>
  );
}

export default App;