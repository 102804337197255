import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Teams.css';

// Importez les images des équipes ici
import magistralImage from '../assets/images/team-magistral.jpg';
import mysteriousImage from '../assets/images/team-mysterious.jpg';
import queensImage from '../assets/images/team-queens.jpg';
import rebirthImage from '../assets/images/team-rebirth.jpg';
import phenomenalImage from '../assets/images/team-phenomenal.jpg';

function Teams() {
  const { t } = useTranslation();
  const [activeTeam, setActiveTeam] = useState(null);

  const teamData = [
    {
      name: "magistral",
      image: magistralImage,
    },
    {
      name: "mysterious",
      image: mysteriousImage,
    },
    {
      name: "queens",
      image: queensImage,
    },
    {
      name: "rebirth",
      image: rebirthImage,
    },
    {
      name: "phenomenal",
      image: phenomenalImage,
    }
  ];

  return (
    <section className="teams">
      <h2>{t('teams.title')}</h2>
      <div className="team-grid">
        {teamData.map((team, index) => (
          <div 
            key={team.name} 
            className={`team-card ${activeTeam === index ? 'active' : ''}`}
            onClick={() => setActiveTeam(activeTeam === index ? null : index)}
          >
            <img src={team.image} alt={t(`teams.teamData.${team.name}.name`)} />
            <h3>{t(`teams.teamData.${team.name}.name`)}</h3>
            <p>{t(`teams.teamData.${team.name}.description`)}</p>
            <div className={`team-details ${activeTeam === index ? 'show' : ''}`}>
              <h4>{t('teams.achievements')}</h4>
              <ul>
                {t(`teams.teamData.${team.name}.achievements`, { returnObjects: true }).map((achievement, i) => (
                  <li key={i}>{achievement}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Teams;